<template>
  <div class="agent_detail_i">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="agent_detail_user">
      <div>
        <i class="el-icon-arrow-left" @click="handleBack"></i>
        {{ this.$route.query.name }} {{ this.$route.query.phoneArea }}
        {{ this.$route.query.phone }}
        <el-tag type="success">{{ isTemp }}{{ agentLevel }}</el-tag>
      </div>
      <div>
        <el-button type="danger" plain round @click="handleCancle"
          >暂停代理权利</el-button
        >
      </div>
    </div>

    <div class="agent_detail_relation">
      <p class="agent_detail_relation_p">推荐关系</p>
      <RelationGraph
        style="height: 350px"
        ref="seeksRelationGraph"
        :options="graphOptions"
        :on-node-click="onNodeClick"
        :on-line-click="onLineClick"
        v-if="!displayNoneTxt"
      />
      <div
        style="height: 200px; line-height: 200px; text-align: center"
        v-else-if="displayNoneTxt"
      >
        暂无关系
      </div>

      <div class="agent_detail_relation_btn">
        <div
          class="agent_detail_relation_btn_d"
          :style="
            agentLevelType == 0
              ? 'background:rgba(0, 180, 90, 0.1);border:1px #00b45a solid;'
              : ''
          "
          style="text-align: center; font-size: 14px"
        >
          <el-tag
            type="success"
            style="width: 98px; margin: 0 auto"
            v-if="agentLevelType == 0"
            >当前身份</el-tag
          >
          <p
            style="font-weight: 400"
            :style="agentLevelType == 0 ? 'color: #00b45a' : ''"
          >
            普通用户
          </p>
        </div>
        <div
          class="agent_detail_relation_btn_d"
          :style="
            agentLevelType == 1
              ? 'background:rgba(0, 180, 90, 0.1);border:1px #00b45a solid;'
              : ''
          "
        >
          <div style="margin-left: 25px">
            <el-tag
              type="success"
              style="margin-left: 0"
              v-if="agentLevelType == 1"
              >当前身份</el-tag
            >
            <p
              class="agent_d_info_p"
              :style="agentLevelType == 1 ? 'color: #00b45a' : ''"
            >
              {{ agentLevelType == 1 ? (isTemp ? "临时-" : "") : "" }}区域代理
            </p>
            <p class="agent_detail_relation_btn_d_dis">
              <img src="@/assets/yes.png" v-if="!isOverFive" alt="" />
              <img
                src="@/assets/gray_yes.png"
                alt=""
                v-if="isOverFive && agentLevelType != 1"
              />
              <img
                src="@/assets/green_yes.png"
                v-else-if="isOverFive && agentLevelType == 1"
                alt=""
              />
              <span
                :style="
                  isOverFive && agentLevelType == 1 ? 'color: #00b45a' : ''
                "
                >5*5</span
              >
            </p>
            <p class="agent_detail_relation_btn_d_dis">
              <img src="@/assets/yes.png" v-if="teamCount < 60" alt="" />
              <img
                src="@/assets/gray_yes.png"
                alt=""
                v-if="teamCount >= 60 && agentLevelType != 1"
              />
              <img
                src="@/assets/green_yes.png"
                v-else-if="teamCount >= 60 && agentLevelType == 1"
                alt=""
              />
              <span
                :style="
                  teamCount >= 60 && agentLevelType == 1 ? 'color: #00b45a' : ''
                "
                >团队成员≥60人（当前{{ teamCount }}人）</span
              >
            </p>

            <el-button
              type="primary"
              plain
              round
              v-if="
                agentLevelType != 1 &&
                agentLevelType != 2 &&
                agentLevelType != 3
              "
              @click="handleUpgrades('area')"
              >升级为临时区代</el-button
            >
          </div>
        </div>
        <div
          class="agent_detail_relation_btn_d"
          :style="
            agentLevelType == 2
              ? 'background:rgba(0, 180, 90, 0.1);border:1px #00b45a solid;'
              : ''
          "
        >
          <div style="margin-left: 25px">
            <el-tag
              type="success"
              style="margin-left: 0"
              v-if="agentLevelType == 2"
              >当前身份</el-tag
            >
            <p
              class="agent_d_info_p"
              :style="agentLevelType == 2 ? 'color: #00b45a' : ''"
            >
              {{ agentLevelType == 2 ? (isTemp ? "临时-" : "") : "" }}市级代理
            </p>
            <p class="agent_detail_relation_btn_d_dis">
              <img src="@/assets/yes.png" v-if="originalLevel !== 1" alt="" />
              <img
                src="@/assets/gray_yes.png"
                alt=""
                v-if="originalLevel == 1 && agentLevelType != 2"
              />
              <img
                src="@/assets/green_yes.png"
                v-else-if="originalLevel == 1 && agentLevelType == 2"
                alt=""
              />
              <span
                :style="
                  originalLevel == 1 && agentLevelType == 2
                    ? 'color: #00b45a'
                    : ''
                "
                >自己是真区代</span
              >
            </p>
            <p class="agent_detail_relation_btn_d_dis">
              <img src="@/assets/yes.png" v-if="cityAgentCount < 3" alt="" />
              <img
                src="@/assets/gray_yes.png"
                alt=""
                v-if="cityAgentCount >= 3 && agentLevelType != 2"
              />
              <img
                src="@/assets/green_yes.png"
                v-else-if="cityAgentCount >= 3 && agentLevelType == 2"
                alt=""
              />
              <span
                :style="
                  cityAgentCount >= 3 && agentLevelType == 2
                    ? 'color: #00b45a'
                    : ''
                "
                >下面直推，三个真区代</span
              >
            </p>

            <el-button
              type="primary"
              plain
              round
              v-if="agentLevelType != 2 && agentLevelType != 3"
              @click="handleUpgrades('city')"
              >升级为临时市代</el-button
            >
          </div>
        </div>
        <div
          class="agent_detail_relation_btn_d"
          :style="
            agentLevelType == 3
              ? 'background:rgba(0, 180, 90, 0.1);border:1px #00b45a solid;'
              : ''
          "
        >
          <div style="margin-left: 25px">
            <el-tag
              type="success"
              style="margin-left: 0"
              v-if="agentLevelType == 3"
              >当前身份</el-tag
            >
            <p
              class="agent_d_info_p"
              :style="agentLevelType == 3 ? 'color: #00b45a' : ''"
            >
              {{ agentLevelType == 3 ? (isTemp ? "临时-" : "") : "" }}省级代理
            </p>
            <p class="agent_detail_relation_btn_d_dis">
              <img src="@/assets/yes.png" v-if="originalLevel != 2" alt="" />
              <img
                src="@/assets/gray_yes.png"
                alt=""
                v-if="originalLevel == 2 && agentLevelType != 3"
              />
              <img
                src="@/assets/green_yes.png"
                v-else-if="originalLevel == 2 && agentLevelType == 3"
                alt=""
              />
              <span
                :style="
                  originalLevel == 2 && agentLevelType == 3
                    ? 'color: #00b45a'
                    : ''
                "
                >自己是真市代</span
              >
            </p>
            <p class="agent_detail_relation_btn_d_dis">
              <img
                src="@/assets/yes.png"
                v-if="provinceAgentCount < 3"
                alt=""
              />
              <img
                src="@/assets/gray_yes.png"
                alt=""
                v-if="provinceAgentCount >= 3 && agentLevelType != 3"
              />
              <img
                src="@/assets/green_yes.png"
                v-else-if="provinceAgentCount >= 3 && agentLevelType == 3"
                alt=""
              />
              <span
                :style="
                  provinceAgentCount >= 3 && agentLevelType == 3
                    ? 'color: #00b45a'
                    : ''
                "
                >下面直推，三个真市代</span
              >
            </p>

            <el-button
              type="primary"
              plain
              round
              v-if="agentLevelType != 3"
              @click="handleUpgrades('province')"
              >升级为临时省代</el-button
            >
          </div>
        </div>
      </div>

      <el-dialog title="升级为临时区代" :visible.sync="dialogFormVisibleArea">
        <div class="el-dialog-body">
          <el-date-picker
            v-model="areaValueStart"
            type="date"
            placeholder="选择开始日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptionsStart"
          >
          </el-date-picker>
          <div>
            <img src="@/assets/123.png" alt="" />
            <p>有效期一个月</p>
          </div>
          <el-date-picker
            v-model="areaValueEnd"
            type="date"
            placeholder="选择结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptionsEnd"
          >
          </el-date-picker>
        </div>
        <div class="el-dialog-body-txt">
          临时区代：默认一个月有效期<br />临时市代：默认两个月有效期<br />
          临时省代：默认三个月有效期
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleArea = false">取 消</el-button>
          <el-button type="primary" @click="handleUpgradesTrue(1)"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <el-dialog title="升级为临时市代" :visible.sync="dialogFormVisibleCity">
        <div class="el-dialog-body">
          <el-date-picker
            v-model="cityValueStart"
            type="date"
            placeholder="选择开始日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptionsStart"
          >
          </el-date-picker>
          <div>
            <img src="@/assets/123.png" alt="" />
            <p>有效期两个月</p>
          </div>
          <el-date-picker
            v-model="cityValueEnd"
            type="date"
            placeholder="选择结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptionsEnd"
          >
          </el-date-picker>
        </div>
        <div class="el-dialog-body-txt">
          临时区代：默认一个月有效期<br />临时市代：默认两个月有效期<br />
          临时省代：默认三个月有效期
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleCity = false">取 消</el-button>
          <el-button type="primary" @click="handleUpgradesTrue(2)"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <el-dialog
        title="升级为临时省代"
        :visible.sync="dialogFormVisibleProvince"
      >
        <div class="el-dialog-body">
          <el-date-picker
            v-model="provinceValueStart"
            type="date"
            placeholder="选择开始日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptionsStart"
          >
          </el-date-picker>
          <div>
            <img src="@/assets/123.png" alt="" />
            <p>有效期三个月</p>
          </div>
          <el-date-picker
            v-model="provinceValueEnd"
            type="date"
            placeholder="选择结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptionsEnd"
          >
          </el-date-picker>
        </div>
        <div class="el-dialog-body-txt">
          临时区代：默认一个月有效期<br />临时市代：默认两个月有效期<br />
          临时省代：默认三个月有效期
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleProvince = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="handleUpgradesTrue(3)"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>

    <div class="agent_detail_table">
      <div class="agent_detail_table_header">
        <p>已获得累计收益：$ {{ gainsTotal }}</p>
        <p style="color: #5267ff; cursor: pointer" @click="handleDetail">
          查看奖励明细<i class="el-icon-arrow-right"></i>
        </p>
      </div>

      <div class="agent_detail_table_d">
        <el-table
          :data="tableDataDetail"
          style="width: 100%"
          :header-cell-style="{
            background: 'rgba(250, 250, 252, 1)',
            color: '#4b4b4b',
          }"
        >
          <el-table-column type="index" label="序号" width="80" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="收益" align="center">
            <template slot-scope="scope"> $ {{ scope.row.amount }} </template>
          </el-table-column>
          <el-table-column prop="afterAmount" label="交易后金额">
            <template slot-scope="scope">
              $ {{ scope.row.afterAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="获取奖励时间"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="title" label="事由" width="80" align="center">
          </el-table-column>

          <el-table-column prop="agentLevel" label="级别" align="center">
            <div>{{ isTemp }}{{ agentLevel }}</div>
          </el-table-column>
        </el-table>

        <el-dialog title="奖励明细" :visible.sync="dialogRewardDetail">
          <div style="margin-bottom: 20px">
            已获得累计收益：$ {{ gainsTotal }}
          </div>
          <el-table :data="tableDataSeeDetail">
            <el-table-column
              type="index"
              label="序号"
              width="80"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.$index + (currentPage - 1) * pageSize + 1
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="收益" align="center">
              <template slot-scope="scope"> $ {{ scope.row.amount }} </template>
            </el-table-column>
            <el-table-column prop="afterAmount" label="交易后金额">
              <template slot-scope="scope">
                $ {{ scope.row.afterAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="获取奖励时间"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="title"
              label="事由"
              width="80"
              align="center"
            >
            </el-table-column>

            <el-table-column prop="agentLevel" label="级别" align="center">
              <div>{{ isTemp }}{{ agentLevel }}</div>
            </el-table-column>
          </el-table>

          <Pagination
            v-show="pageLength >= 0"
            :total="pageLength"
            :limit.sync="pageSize"
            @pagination="handlePageChange"
            :page-sizes="pageSizes"
            :page="currentPage"
          />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

import { format_time_date } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "代理/运营",
        },
        {
          name: "用户代理级别查询",
        },
      ], // 面包屑数据

      graphOptions: {
        backgrounImageNoRepeat: true,
        layouts: [
          {
            label: "中心",
            layoutName: "tree",
            layoutClassName: "seeks-layout-center",
            defaultJunctionPoint: "border",
            // distance_coefficient: 0.6,
            min_per_width: "145",
            max_per_width: "170",
            min_per_height: "160",
          },
        ],
        defaultLineMarker: {
          markerWidth: 12,
          markerHeight: 12,
          refX: 6,
          refY: 6,
          data: "M2,2 L10,6 L2,10 L6,6 L2,2",
        },
        moveToCenterWhenRefresh: true,
        defaultExpandHolderPosition: "bottom",
        defaultNodeShape: 1,
        defaultNodeWidth: "140",
        defaultNodeHeight: "50",
        defaultLineShape: 4,
        defaultJunctionPoint: "lr",
        defaultNodeBorderWidth: 0,
        defaultNodeColor: "rgba(244, 244, 244, 1)",
        defaultNodeFontColor: "rgba(0, 0, 0, 1)",
        defaultNodeBorderColor: "#A0A0A0",
        defaultLineColor: "rgba(154, 154, 154, 1)",
        disableZoom: true,
      },

      agentType: 0,
      displayNoneTxt: false,

      agentLevel: 0, // 身份
      agentLevelType: 0,
      originalLevel: false, // 判断自己是否为真区代/真市代
      cityAgentCount: 0, // 下面直推，三个真区代
      isOverFive: false, // 5*5
      provinceAgentCount: 0, // 下面直推，三个真市代
      teamCount: 0, // 团队成员
      isTemp: false, // 是否为临时

      dialogFormVisibleArea: false,
      dialogFormVisibleCity: false,
      dialogFormVisibleProvince: false,

      areaValueStart: "",
      areaValueEnd: "",
      cityValueStart: "",
      cityValueEnd: "",
      provinceValueStart: "",
      provinceValueEnd: "",

      pickerOptionsStart: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pickerOptionsEnd: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },

      tableDataDetail: [],
      tableDataSeeDetail: [],
      dialogRewardDetail: false,

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],

      gainsTotal: "", // 累计总收益
    };
  },

  created() {
    this.getDefaultTime();
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.getAgentLevelDetail,
      method: "POST",
      params: JSON.stringify({
        userId: this.$route.query.userId * 1,
        userName: this.$route.query.name,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          if (res.data.data) {
            this.agentLevel = res.data.data.agentLevel;
            this.agentLevelType = res.data.data.agentLevel;
            this.cityAgentCount = res.data.data.cityAgentCount;
            this.isOverFive = res.data.data.isOverFive;
            this.provinceAgentCount = res.data.data.provinceAgentCount;
            this.teamCount = res.data.data.teamCount;
            this.isTemp = res.data.data.isTemp;
            this.originalLevel = res.data.data.originalLevel;
            res.data.data.isTemp == true
              ? (this.isTemp = "临时-")
              : (this.isTemp = "");

            res.data.data.agentLevel == 0
              ? (this.agentLevel = "非代理")
              : res.data.data.agentLevel == 1
              ? (this.agentLevel = "区域代理")
              : res.data.data.agentLevel == 2
              ? (this.agentLevel = "市级代理")
              : res.data.data.agentLevel == 3
              ? (this.agentLevel = "省级代理")
              : "";

            const __graph_json_data = {
              rootId: res.data.data.rootId,
              nodes: res.data.data.nodes,
              lines: res.data.data.lines || [],
            };

            this.$refs.seeksRelationGraph.setJsonData(
              __graph_json_data,
              (graphInstance) => {
                // 这些写上当图谱初始化完成后需要执行的代码
              }
            );
          } else {
            this.displayNoneTxt = true;
          }

          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);

    setTimeout(() => {
      const optTable = {
        url: reqUrl.getBoxAgentBalanceDetail,
        method: "POST",
        params: JSON.stringify({
          appLang: "zh-CN", // TW("zh-TW", new Locale("zh-TW")),CN("zh-CN", new Locale("zh-CN")),KR("ko-KR", new Locale("ko-KR")),JP("ja-JP", new Locale("ja-JP")),US("en-US", new Locale("en-US")),
          userId: this.$route.query.userId,
          pageSize: 5,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableDataDetail = res.data.data.list;
            res.data.data.list.map(
              (item) => (item.createTime = format_time_date(item.createTime))
            );
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(optTable);

      const optMoney = {
        url: reqUrl.getBoxAgentBalanceSum,
        method: "POST",
        params: JSON.stringify({
          userId: this.$route.query.userId,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.gainsTotal = res.data.data;
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(optMoney);
    }, 500);
  },

  methods: {
    getDefaultTime() {
      const start = `${dayjs().subtract(0, "day").format("YYYY-MM-DD")}`;
      const endArea = `${dayjs().add(1, "month").format("YYYY-MM-DD")}`;
      const endCity = `${dayjs().add(2, "month").format("YYYY-MM-DD")}`;
      const endProvince = `${dayjs().add(3, "month").format("YYYY-MM-DD")}`;

      this.areaValueStart = start;
      this.cityValueStart = start;
      this.provinceValueStart = start;
      this.areaValueEnd = endArea;
      this.cityValueEnd = endCity;
      this.provinceValueEnd = endProvince;
    },

    onNodeClick(nodeObject, $event) {
      console.log("onNodeClick:", nodeObject);
    },

    onLineClick(lineObject, linkObject, $event) {
      console.log("onLineClick:", lineObject);
    },

    handleBack() {
      this.$router.push("/AgentIndex");
    },

    handleUpgrades(type) {
      if (type == "area") {
        this.dialogFormVisibleArea = true;
      } else if (type == "city") {
        this.dialogFormVisibleCity = true;
      } else if (type == "province") {
        this.dialogFormVisibleProvince = true;
      }
    },

    handleUpgradesTrue(type) {
      showLoading();
      const opt = {
        url: reqUrl.setTempAgent,
        method: "POST",
        params: JSON.stringify({
          userId: this.$route.query.userId * 1,
          agentLevel: type,
          hardwareId: this.$route.query.hardwareId,
          startTime: this.areaValueStart,
          endTime: this.areaValueEnd,
          remark: "",
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "升级成功",
              type: "success",
            });
            this.refresh();
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$message.error("升级失败,请重新操作");
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleCancle() {
      this.$confirm("您确认是否进行“暂停代理”的操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          showLoading();
          const opt = {
            url: reqUrl.cancelTempAgent,
            method: "POST",
            params: JSON.stringify({
              userId: this.$route.query.userId * 1,
              hardwareId: this.$route.query.hardwareId,
              remark: "",
            }),
            resFunc: (res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: "暂停代理操作成功",
                  type: "success",
                });
                this.refresh();
                hideLoading();
              }

              if (res.data.code != 0) {
                this.$message.error("暂停代理操作失败,请重新操作");
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    handleDetail() {
      this.dialogRewardDetail = true;
      const optTable = {
        url: reqUrl.getBoxAgentBalanceDetail,
        method: "POST",
        params: JSON.stringify({
          appLang: "zh-CN",
          userId: this.$route.query.userId,
          pageSize: 20,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableDataSeeDetail = res.data.data.list;
            res.data.data.list.map(
              (item) => (item.createTime = format_time_date(item.createTime))
            );
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(optTable);
    },

    handlePageChange(data) {
      const optTable = {
        url: reqUrl.getBoxAgentBalanceDetail,
        method: "POST",
        params: JSON.stringify({
          appLang: "zh-CN",
          userId: this.$route.query.userId,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableDataSeeDetail = res.data.data.list;
            res.data.data.list.map(
              (item) => (item.createTime = format_time_date(item.createTime))
            );
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(optTable);
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tag.el-tag--success {
  background-color: rgba(0, 180, 90, 0.1);
  color: #00b45a;
  margin-left: 5px;
}

.agent_detail_i {
  position: relative;
  height: calc(100vh - 40px);
  overflow-y: scroll;

  .agent_detail_user {
    height: 60px;
    border-radius: 20px;
    background: #fff;
    line-height: 60px;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    i {
      cursor: pointer;
    }
  }

  .agent_detail_relation {
    width: 100%;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 20px;
    padding-bottom: 20px;

    .agent_detail_relation_p {
      padding: 20px;
      padding-bottom: 0;
    }

    .agent_detail_relation_btn {
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;

      .agent_detail_relation_btn_d {
        width: 230px;
        height: 108px;
        background: #f5f5f5;
        border-radius: 10px;
        border: 1px #ebedf2 solid;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 400;

        .agent_d_info_p {
          margin-bottom: 5px;
          font-weight: 400;
          font-size: 14px;
        }

        .agent_detail_relation_btn_d_dis {
          display: flex;
          align-items: center;
          color: #7d828c;
          font-weight: 400;
          font-size: 13px;

          .agent_detail_relation_btn_d_circle {
            width: 12px;
            height: 12px;
            border: 1px rgba(235, 237, 242, 1) solid;
            display: block;
            border-radius: 30px;
            background: #fff;
            margin-right: 4px;
          }

          img {
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }
        }

        ::v-deep .el-button.is-round {
          padding: 8px 13px;
          margin-top: 5px;
          font-size: 11px;
        }
      }
    }

    ::v-deep .el-dialog {
      width: 38%;
      border-radius: 20px;

      .el-dialog-body {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 72px;
          height: 10px;
          margin: 0 15px;
        }

        p {
          text-align: center;
          font-size: 12px;
        }
      }

      .el-dialog-body-txt {
        text-align: center;
        margin-top: 20px;
        color: #7d828c;
        font-weight: 400;
      }
    }

    .dialog-footer {
      display: flex;
      justify-content: center;

      .el-button {
        width: 50%;
        border-radius: 20px;
      }
    }
  }

  .agent_detail_table {
    width: 100%;
    border-radius: 20px;
    background: #fff;
    margin: 10px 0;
    padding-bottom: 20px;

    .agent_detail_table_header {
      display: flex;
      justify-content: space-between;
      margin: 0 20px;
      padding: 20px 0;

      p {
        font-weight: 400;
      }
    }

    .agent_detail_table_d {
      border: 1px #ebedf2 solid;
      border-radius: 10px;
      margin: 0 20px;

      .el-table {
        border-radius: 10px;
      }

      ::v-deep .el-dialog {
        margin-top: 4vh !important;
        width: 80%;
      }
    }
  }
}
</style>

<style>
.rel-map {
  height: 400px !important;
}

.rel-node-shape-1 .c-node-text {
  padding-left: 0 !important;
}

.c-node-text {
  font-weight: 400;
  font-size: 20px;
}
</style>
